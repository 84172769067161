document.addEventListener("DOMContentLoaded", () => {
    const cart_fast = document.getElementById("cart-fast-content");
    const quick_cart_triggers = document.querySelectorAll(
        '[data-zm-quick-cart="trigger"]'
    );
    const /** @type HTMLElement[] */ quick_cart_numbers =
            document.querySelectorAll('[data-zm-quick-cart="trigger"] em');
    if (!cart_fast || !quick_cart_triggers) {
        return;
    }

    function openFastCart() {
        if (!cart_fast.classList.contains("tw-hidden")) {
            // already opened
            return;
        }
        if (!cart_fast.classList.contains("tw-translate-x-full")) {
            cart_fast.classList.add("tw-translate-x-full");
        }
        cart_fast.classList.remove("tw-hidden");
        cart_fast.classList.add("tw-flex");
        setTimeout(() => {
            cart_fast.classList.remove("tw-translate-x-full");
        }, 0);

        const view_cart_ecommerce_object = getViewCartEcommerceObject(cart_fast);

        if (gDataLayer && view_cart_ecommerce_object) {
            gDataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            gDataLayer.push({
                "event": "view_cart",
                "ecommerce": view_cart_ecommerce_object,
            });
        }
    }

    function closeFastCart() {
        if (cart_fast.classList.contains("tw-hidden")) {
            // already closed
            return;
        }
        cart_fast.classList.add("tw-translate-x-full");
        cart_fast.addEventListener(
            "transitionend",
            () => {
                cart_fast.classList.add("tw-hidden");
            },
            { once: true }
        );
    }

    quick_cart_triggers.forEach((quick_cart_trigger) => {
        quick_cart_trigger.addEventListener("click", (e) => {
            if(quick_cart_trigger.tagName === "A") {
                return true;
            }
            e.preventDefault();
            openFastCart();
        });
    });

    cart_fast.addEventListener("click", (/** @type Event */ e) => {
        // console.log(e.target);
        if (
            e.target.matches(
                '[data-cart-fast="close"],[data-cart-fast="close"] *'
            )
        ) {
            closeFastCart();
            return;
        }

        if (
            e.target.matches(
                '[data-cart-fast="decreaseQuantity"],[data-cart-fast="increaseQuantity"]'
            )
        ) {
            cartFastChangeQuantity(cart_fast, quick_cart_numbers, e.target);
            return;
        }
        if (
            e.target.matches(
                '[data-cart-fast="remove"],[data-cart-fast="remove"] *'
            )
        ) {
            let clickedButton = e.target;
            if (clickedButton.tagName.toLowerCase() !== "button") {
                clickedButton = e.target.closest("button");
            }
            cartFastRemove(cart_fast, quick_cart_numbers, clickedButton);
        }
    });

    document.addEventListener("refresh-fast-cart", () => {
        refreshCart(cart_fast, quick_cart_numbers).then(() => {
            openFastCart();
        })
    });
});

function getCartItemObject(cart_item) {
    let item_categories = {};
    [
        "item_category",
        "item_category2",
        "item_category3",
        "item_category4",
        "item_category5",
    ].forEach((cat) => {
        if (cart_item.dataset[cat]) {
            item_categories[cat] = cart_item.dataset[cat];
        }
    });
    return {
        item_id: cart_item.dataset.item_id,
        item_name: cart_item.dataset.item_name,
        item_brand: cart_item.dataset.item_brand,
        ...item_categories,
        price: cart_item.dataset.price,
        quantity: cart_item.dataset.quantity,
        value: cart_item.dataset.value,
    };
}

function getViewCartEcommerceObject(cart_fast) {
    if (!cart_fast.querySelector("[data-view_cart-value]")) {
        return null;
    }

    const cart = cart_fast.querySelector("[data-view_cart-value]");
    return {
        value: cart.dataset.view_cartValue,
        currency: cart.dataset.view_cartCurrency,
        items: Array.from(cart.querySelectorAll("[data-item_id]")).map(getCartItemObject),
    };
}

/**
 *
 * @param {HTMLDivElement} cart_fast
 * @param {HTMLElement[]} quick_cart_numbers
 */
function refreshCart(cart_fast, quick_cart_numbers) {
    return window
        .fetch("/refreshcart?version=2")
        .then((r) => r.text())
        .then((html) => {
            cart_fast.innerHTML = html;
            let totalnumber = 0;
            cart_fast
                .querySelectorAll('[data-cart-fast="quantity"] input')
                .forEach((qty) => {
                    totalnumber += parseInt(qty.value);
                });
            quick_cart_numbers.forEach((quick_cart_number) => {
                quick_cart_number.innerText = totalnumber;
            });
        });
}

/**
 * @param {HTMLDivElement} cart_fast
 * @param {HTMLElement[]} quick_cart_numbers
 * @param {HTMLButtonElement} clickedButton
 */
function cartFastChangeQuantity(cart_fast, quick_cart_numbers, clickedButton) {
    const changeQuantity = clickedButton.closest('[data-cart-fast="quantity"]'),
        min = parseInt(changeQuantity.dataset.min),
        max = parseInt(changeQuantity.dataset.max),
        quantity = parseInt(changeQuantity.querySelector("input").value),
        operation = clickedButton.dataset.cartFast;

    if (
        ("increaseQuantity" === operation && quantity >= max) ||
        ("decreaseQuantity" === operation && quantity <= min) ||
        !window.csrf_token
    ) {
        return;
    }

    const /** @type HTMLDivElement */ productRow = clickedButton.closest(
            "[data-cart_item_id]"
        );
    const cart_item_id = productRow.dataset.cart_item_id;
    clickedButton.innerHTML = '<i class="fas fa-spinner fa-pulse fa-fw"></i>';
    // alert(
    //     `cart_item_id: ${cart_item_id}, quantity: ${quantity}, operation: ${operation}`
    // );
    let count;
    switch (operation) {
        case "increaseQuantity":
            count = quantity + 1;
            break;
        case "decreaseQuantity":
            count = quantity - 1;
            break;
    }
    const changeCountData = new FormData();
    changeCountData.append("id", cart_item_id);
    changeCountData.append("count", count);
    changeCountData.append("_token", window.csrf_token);

    window
        .fetch("/changecount", { method: "POST", body: changeCountData })
        .then(() => {
            refreshCart(cart_fast, quick_cart_numbers);
        });
}

/**
 *
 * @param {HTMLDivElement} cart_fast
 * @param {HTMLElement} quick_cart_numbers
 * @param {HTMLButtonElement} clickedButton
 */
function cartFastRemove(cart_fast, quick_cart_numbers, clickedButton) {
    const /** @type HTMLDivElement */ productRow = clickedButton.closest(
            "[data-cart_item_id]"
        );
    const cart_item_id = productRow.dataset.cart_item_id;
    clickedButton.innerHTML = '<i class="fas fa-spinner fa-pulse fa-fw"></i>';
    // alert(`cartFastRemove: ${cart_item_id}`);
    const removeFormData = new FormData();
    removeFormData.append("id", cart_item_id);
    removeFormData.append("_token", window.csrf_token);

    const remove_dataLayer_item_object = getCartItemObject(productRow);
    const remove_dataLayerEvent = {
        event: "remove_from_cart",
        ecommerce: {
            value: remove_dataLayer_item_object.value,
            currency: "TRY",
            items: [
                remove_dataLayer_item_object,
            ],
        },
    };
    window
        .fetch("/deletecart", { method: "POST", body: removeFormData })
        .then(() => {
            refreshCart(cart_fast, quick_cart_numbers);
            if(gDataLayer) {
                gDataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                gDataLayer.push(remove_dataLayerEvent);
            }
        });
}
