document.addEventListener("DOMContentLoaded", () => {
    /** @type HTMLInputElement[] */
    const typers = document.querySelectorAll("[data-placeholder-typer]");

    typers.forEach((typer) => {
        const words = typer.dataset.placeholderTyper.split(",");
        // console.log("typer", typer);
        // console.log("words", words);

        // get current word, or next word
        let currentWordIndex = 0,
            currentLetterIndex = 0,
            currentAction = "writing";
        // write it letter by letter until done - 200ms delay
        // or delete it letter by letter until done - 100ms delay
        // go to start

        function typerWrite() {
            const currentWord = words[currentWordIndex];
            switch (currentAction) {
                case "writing":
                    currentLetterIndex++;
                    if (currentLetterIndex > currentWord.length) {
                        currentAction = "deleting";
                    }
                    break;
                case "deleting":
                    currentLetterIndex--;
                    if (currentLetterIndex <= 0) {
                        // next word, or first word
                        currentWordIndex =
                            (currentWordIndex + 1) % words.length;
                        currentAction = "writing";
                    }
                    break;
            }
            typer.placeholder = currentWord.slice(0, currentLetterIndex);
            setTimeout(typerWrite, currentAction === "writing" ? 200 : 100);
        }
        typerWrite();
    });
});
