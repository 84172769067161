window.sepetHome = function (id, sku) {
    console.log("sepetHome");
};

function getAddToCartEcommerceObjectFromJson(json) {
    const json_product = json.product;
    let item_categories = {};
    const categories = json_product.ctg.split("/");
    [
        "item_category",
        "item_category2",
        "item_category3",
        "item_category4",
        "item_category5",
    ].forEach((cat, cat_index) => {
        if (categories.length > cat_index) {
            item_categories[cat] = categories[cat_index];
        }
    });
    return {
        value: json.product.price,
        currency: "TRY",
        items: [
            {
                item_id: json_product.id,
                item_name: json_product.name,
                item_brand: json_product.brand,
                ...item_categories,
                price: json_product.price,
                quantity: json_product.number,
            },
        ],
    };
}

document.addEventListener("DOMContentLoaded", () => {
    if (!window.csrf_token) {
        return;
    }
    const /** @type HTMLButtonElement[] */ add_to_carts =
            document.querySelectorAll("[data-add-to-cart]");
    add_to_carts.forEach((add_to_cart) => {
        add_to_cart.addEventListener("click", () => {
            const existingHTML = add_to_cart.innerHTML;
            const add_object = JSON.parse(add_to_cart.dataset.addToCart);
            add_to_cart.innerHTML =
                '<i class="fas fa-spinner fa-pulse fa-fw"></i>';
            const addToCartFormData = new FormData();
            addToCartFormData.append("_token", window.csrf_token);
            addToCartFormData.append("sku", add_object.sku);
            addToCartFormData.append("number", "1");
            window
                .fetch(
                    add_object.insertcart_route.replace(
                        "https:",
                        document.location.protocol
                    ),
                    {
                        method: "POST",
                        body: addToCartFormData,
                    }
                )
                .then((response) => response.json())
                .then((json) => {
                    // console.log("insertcart response json", json);
                    const add_to_cart_object = getAddToCartEcommerceObjectFromJson(json);
                    // console.log("add_to_cart_object", add_to_cart_object);
                    if (gDataLayer) {
                        gDataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
                        gDataLayer.push({
                            event: "add_to_cart",
                            ecommerce: add_to_cart_object,
                        });
                    }
                    document.dispatchEvent(
                        new CustomEvent("refresh-fast-cart")
                    );
                    add_to_cart.innerHTML = existingHTML;
                });
        });
    });
});
