document.addEventListener("DOMContentLoaded", () => {
    const yukariCik = document.querySelector(".yukariCik");
    if (!yukariCik) {
        return;
    }
    window.addEventListener("scrollend", () => {
        if (window.scrollY > 100) {
            yukariCik.classList.remove("tw-hidden");
            yukariCik.classList.add("tw-block");
        } else {
            yukariCik.classList.add("tw-hidden");
            yukariCik.classList.remove("tw-block");
        }
    });

    yukariCik.addEventListener("click", () => {
        window.scrollTo({ top: 0 });
    });
});
