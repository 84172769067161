document.addEventListener("DOMContentLoaded", () => {
    const breakpoint_lg = window.matchMedia("(min-width: 992px)");
    const /** @type HTMLElement */ zm_header =
            document.querySelector(".zm-header");
    if (breakpoint_lg.matches) {
        activateLg();
    }
    breakpoint_lg.addEventListener("change", () => {
        if (breakpoint_lg.matches) {
            activateLg();
            return;
        }
        deActivateLg();
    });

    const DELTA = 40;
    let previousScrollPosition = 0;

    function windowOnScrollend() {
        if (
            window.scrollY < previousScrollPosition - DELTA ||
            window.scrollY < zm_header.offsetHeight
        ) {
            stickyShow();
        } else {
            stickyHide();
        }
        previousScrollPosition = window.scrollY;
    }

    function activateLg() {
        /*
        zm_header.classList.remove("sticky");
        zm_header.classList.add("stickyup");
        window.addEventListener("scrollend", windowOnScrollend);*/

        /* scrollend ile tetiklendiği için en yukarı çıkıldığında beyaz bir ekran karşılamakta */
        var htmlElement = document.querySelector("html");
        var fakeHeader = document.querySelector(".fakeheader");
        var zmHeader = document.querySelector(".zm-header");
        var zmCategoryFilters = document.querySelector(".zm-category-filters");

        htmlElement.addEventListener(("onwheel" in document.createElement("div") ? "wheel" : "mousewheel"), function (e) {
            if (window.innerWidth > 991) {
                var evt = e || window.event;
                var deltaY = evt.deltaY || (-1 / 40 * evt.wheelDelta);
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

                if (scrollTop > 122) {
                    fakeHeader.style.height = "190px";

                    if (deltaY > 0) {
                        zmHeader.classList.add("sticky");
                        zmCategoryFilters.style.top = "47px";
                        zmHeader.classList.remove("stickyup");
                    } else if (deltaY < -20) {
                        zmCategoryFilters.style.top = "188px";
                        zmHeader.classList.add("stickyup");
                        zmHeader.classList.remove("sticky");
                    }
                } else {
                    fakeHeader.style.height = "0";
                    zmHeader.classList.remove("stickyup");
                    zmHeader.classList.remove("sticky");
                }
            }
        });

    }

    function deActivateLg() {
        zm_header.classList.remove("sticky");
        zm_header.classList.remove("stickyup");
        window.removeEventListener("scrollend", windowOnScrollend);
    }

    function stickyShow() {
        zm_header.classList.add("stickyup");
        zm_header.classList.remove("sticky");
    }

    function stickyHide() {
        zm_header.classList.remove("stickyup");
        zm_header.classList.add("sticky");
    }
});
