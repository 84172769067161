document.addEventListener("DOMContentLoaded", () => {
    const /** @type HTMLButtonElement[] */ mobile_menu_buttons =
            document.querySelectorAll('[data-mobile-menu="button_v2"]'),
        /** @type HTMLElement */ mobil_headmenu = document.querySelector(
            "[data-mobil-headmenu-state]"
        );

    if (!mobile_menu_buttons || !mobil_headmenu) {
        return;
    }

    mobile_menu_buttons.forEach((mobile_menu_button) => {
        const body_overflow_classes = ["tw-overflow-hidden"];
        mobile_menu_button.addEventListener("click", () => {
            if (
                mobil_headmenu.getAttribute("data-mobil-headmenu-state") ===
                "closed"
            ) {
                mobil_headmenu.setAttribute(
                    "data-mobil-headmenu-state",
                    "changing"
                );
                document.body.classList.add(...body_overflow_classes);
                mobil_headmenu.classList.remove("tw-hidden");
                setTimeout(() => {
                    mobil_headmenu.classList.remove("-tw-translate-x-full");
                    mobil_headmenu.scrollTo({ left: 0, top: 0 });
                    mobil_headmenu.setAttribute(
                        "data-mobil-headmenu-state",
                        "open"
                    );
                }, 0);
            }

            if (
                mobil_headmenu.getAttribute("data-mobil-headmenu-state") ===
                "open"
            ) {
                mobil_headmenu.setAttribute(
                    "data-mobil-headmenu-state",
                    "changing"
                );
                mobil_headmenu.classList.add("-tw-translate-x-full");
                mobil_headmenu.addEventListener(
                    "transitionend",
                    () => {
                        mobil_headmenu.classList.add("tw-hidden");
                        document.body.classList.remove(
                            ...body_overflow_classes
                        );
                        mobil_headmenu.setAttribute(
                            "data-mobil-headmenu-state",
                            "closed"
                        );
                    },
                    { once: true }
                );
            }
        });
    });
//zm-category-cards__item
    const /** @type HTMLElement[] */ card_items =
            mobil_headmenu.querySelectorAll(".zm-category-cards__item");
    card_items.forEach((card_item) => {
        card_item.addEventListener("click", (e) => {
            const url = card_item.getAttribute('href');
            const /** @type HTMLElement */ subtopmenu = document.querySelector(
                    `.subtopmenu[url="${url}"]`
                );
            if (!subtopmenu) {
                return true;
            }
            e.preventDefault();
            //save scroll position
            mobil_headmenu.setAttribute(
                "data-scroll-pos",
                mobil_headmenu.scrollTop
            );
            mobil_headmenu.scrollTo(0, 0);
            mobil_headmenu.classList.remove("tw-overflow-auto");
            mobil_headmenu.classList.add("tw-overflow-hidden");
            //begin animation
            subtopmenu.style.left = "0";
        });
    });

    const /** @type HTMLElement[] */ subtopmenus =
            document.querySelectorAll(".subtopmenu[url]");
    subtopmenus.forEach((subtopmenu) => {
        subtopmenu.addEventListener("click", (e) => {
            if (e.target.classList.contains("subtopmenuheader")) {
                subtopmenu.style.left = "-100%";
                mobil_headmenu.classList.add("tw-overflow-auto");
                mobil_headmenu.classList.remove("tw-overflow-hidden");
                //get scroll position, scroll to and remove saved data
                if (mobil_headmenu.hasAttribute("data-scroll-pos")) {
                    const scrollPos = parseInt(
                        mobil_headmenu.getAttribute("data-scroll-pos")
                    );
                    mobil_headmenu.scrollTo({ top: scrollPos });
                    mobil_headmenu.removeAttribute("data-scroll-pos");
                }
            }
        });
    });

    mobil_headmenu
        .querySelectorAll(".subtopmenu [aria-checked]")
        .forEach((group) => {
            group.addEventListener("click", (e) => {
                if (e.target.tagName.toLowerCase() === "button") {
                    group.setAttribute(
                        "aria-checked",
                        group.getAttribute("aria-checked") === "true"
                            ? "false"
                            : "true"
                    );
                }
            });
        });
});
