import { createObserver } from "./createObserver";

document.addEventListener("DOMContentLoaded", () => {
    const data_fancybox_elements = document.querySelectorAll("[data-fancybox]");
    const fancyboxObserver = createObserver(0.1);
    data_fancybox_elements.forEach((element) => {
        fancyboxObserver.observe(element);
        element.addEventListener("intersecting", () => {
            if('Fancybox' in window) {
                return;
            }
            import("@fancyapps/ui").then((imported) => {
                window.Fancybox = imported.Fancybox;
                window.Fancybox.bind('[data-fancybox]');
            });
        });
    });
});