document.addEventListener("DOMContentLoaded", () => {
    const zm_footer_button = document.querySelector(".zm-footer-button");
    if (!zm_footer_button) {
        return;
    }
    zm_footer_button.addEventListener("click", () => {
        const i = zm_footer_button.querySelector("i");
        i.classList.toggle("icon-hamburger");
        i.classList.toggle("icon-x-thin");
        document
            .querySelectorAll(
                ".zm-footer > .zm-footer__container,.zm-footer > .zm-footer__infos"
            )
            .forEach((ft) => {
                ft.classList.toggle("hidden");
            });
        window.scrollBy({ top: 300 });
    });
});
