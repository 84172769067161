document.addEventListener("DOMContentLoaded", () => {
    const /** @type HTMLElement */ cookie = document.querySelector(".cookie"),
        /** @type HTMLButtonElement */ hide_cookie =
            document.querySelector(".hide-cookie");
    if (!cookie || !hide_cookie) {
        return;
    }
    cookie.classList.remove("tw-hidden");
    hide_cookie.addEventListener(
        "click",
        () => {
            cookie.remove();
            fetch("/cookie");
        },
        { once: true }
    );
});
