document.addEventListener("DOMContentLoaded", () => {
    const haber_modal2 = document.getElementById("haber-modal2");
    const haber_modal2_ok = document.getElementById("haber-modal2-ok");
    if (!haber_modal2 || !haber_modal2_ok) {
        return;
    }
    const haber_modal_form = haber_modal2.querySelector("form");
    if (!haber_modal_form) {
        return;
    }
    const haber_modal_buttons = document.querySelectorAll(
        "[data-haber-modal2-product-id]"
    );
    haber_modal_buttons.forEach((haber_modal_button) => {
        haber_modal_button.addEventListener("click", () => {
            window.showHaberModal(
                haber_modal_button.dataset.haberModal2ProductId
            );
        });
    });

    const haber_modal_button = haber_modal_form.querySelector(
        "button[type='submit']"
    );
    haber_modal_form.addEventListener("submit", (e) => {
        e.preventDefault();

        if (!haber_modal_form.reportValidity()) {
            return;
        }

        const email = haber_modal_form.querySelector(
                'input[name="email"]'
            ).value,
            product_id = haber_modal_form.querySelector(
                'input[name="product_id"]'
            ).value;

        const existing_button_html = haber_modal_button.innerHTML;
        haber_modal_button.innerHTML =
            '<i class="fas fa-spinner fa-pulse fa-fw"></i>';
        window.haberModalBackend(email, product_id).then((returnCode) => {
            if ("2" === returnCode) {
                haber_modal_button.innerHTML = existing_button_html;
                alert("Bu ürün için önceden kayıt olmuşsunuz.");
                haber_modal_form.reset();
                window.haber_modal2.close();
                return;
            }
            if ("1" === returnCode) {
                haber_modal_button.innerHTML = existing_button_html;

                haber_modal_form.reset();
                new window.Fancybox(
                    [
                        {
                            src: "#haber-modal2-ok",
                            type: "inline",
                        },
                    ],
                    {
                        hideScrollbar: true,
                        animated: true,
                        dragToClose: false,
                    }
                );
                window.haber_modal2.close();
                return;
            }
            alert("Bir hata meydana geldi.");
        });
    });
    window.showHaberModal = (product_id) => {
        if (!window.Fancybox) {
            import("@fancyapps/ui").then((imported) => {
                window.Fancybox = imported.Fancybox;
                window.showHaberModal(product_id);
            });
            return;
        }
        const product_id_input = document.querySelector(
            '#haber-modal2 input[name="product_id"]'
        );
        if (!product_id_input) {
            return;
        }
        product_id_input.value = product_id;
        window.haber_modal2 = new window.Fancybox(
            [
                {
                    src: "#haber-modal2",
                    type: "inline",
                },
            ],
            {
                hideScrollbar: true,
                animated: true,
                dragToClose: false,
            }
        );
    };
    window.haberModalBackend = (email, product_id) => {
        const haberModalFormData = new FormData();
        haberModalFormData.append("_token", window.csrf_token);
        haberModalFormData.append("mail", email);
        haberModalFormData.append("product_id", product_id);
        return window
            .fetch("/news-stock", {
                method: "POST",
                body: haberModalFormData,
            })
            .then((r) => r.text())
            .then((data) => {
                console.log(data);
                //1: Başarılı
                //2: Önceden kaydedilmiş
                return data;
            })
            .catch((reason) => {
                return reason;
            });
    };
});
