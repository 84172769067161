document.addEventListener("DOMContentLoaded", () => {
    /** @type HTMLFormElement[] */
    const qs_forms = document.querySelectorAll('[data-search="form"]'),
        /** @type HTMLElement */
        qs_overlay = document.querySelector(".zm-quick-search-overlay"),
        /** @type HTMLElement */
        qs_results = document.querySelector(".zm-quick-search-results");
    if (!qs_forms || qs_forms.length === 0 || !qs_overlay || !qs_results) {
        return;
    }
    function measureHeaderHeight() {
        /** @type HTMLElement */
        const zm_header = document.querySelector(".zm-header");
        document.documentElement.style.setProperty(
            "--zm-header-height",
            `${zm_header.offsetHeight}px`
        );
        const zm_after_header_height =
            window.innerHeight - zm_header.offsetHeight;
        document.documentElement.style.setProperty(
            "--zm-after-header-height",
            `${zm_after_header_height}px`
        );
    }
    measureHeaderHeight();
    window.addEventListener("resize", measureHeaderHeight);

    qs_forms.forEach((qs_form) => {
        qs_form.addEventListener("reset", () => {
            qs_results.innerHTML = "";
            document.documentElement.setAttribute("data-search-state", "false");
        });

        const qs_input = qs_form.querySelector("input");
        if (qs_input && window.csrf_token) {
            qs_input.addEventListener("keyup", () => {
                if (window.qs_timeout_id) {
                    window.clearTimeout(window.qs_timeout_id);
                }
                if (
                    qs_input.value ===
                    qs_input.getAttribute("data-quick-searched")
                ) {
                    return;
                }
                if (qs_input.value.length === 0) {
                    window.qs_timeout_id = window.setTimeout(() => {
                        qs_results.innerHTML = "";
                        document.documentElement.setAttribute(
                            "data-search-state",
                            "false"
                        );
                    }, 300);
                    return;
                }
                window.qs_timeout_id = window.setTimeout(() => {
                    // Request atılmış kelimeyi değişmedikçe tekrar istememek için
                    // Yön tuşları, shift gibi durumlarda
                    qs_input.setAttribute(
                        "data-quick-searched",
                        qs_input.value
                    );
                    quickSearchRequest(qs_input.value, window.csrf_token);
                }, 300);
            });
        }
    });

    function quickSearchRequest(q, csrf) {
        document.documentElement.setAttribute("data-search-state", "true");
        qs_results.innerHTML = "";
        qs_results.insertAdjacentHTML(
            "afterbegin",
            '<i class="fas fa-spinner fa-pulse fa-fw fa-2x"></i>'
        );
        const quickSearchFormData = new FormData();
        quickSearchFormData.append("q", q);
        quickSearchFormData.append("_token", csrf);
        window
            .fetch("/neler-var", {
                method: "POST",
                body: quickSearchFormData,
            })
            .then((r) => r.text())
            .then((response_html) => {
                qs_results.innerHTML = "";
                qs_results.insertAdjacentHTML("afterbegin", response_html);
            });
    }
});
