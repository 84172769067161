window.like = (id, type) => {
    if ("product" !== type || !window.csrf_token) {
        return;
    }
    const likeFormHeaders = new Headers({
        "X-Csrf-Token": window.csrf_token,
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    });
    const likeFormData = new FormData();
    likeFormData.append("id", id);
    likeFormData.append("type", type);

    fetch("ajax/like", {
        method: "PUT",
        headers: likeFormHeaders,
        body: `id=${id}&type=${type}`,
    })
        .then((response) => response.json())
        .then((data) => {
            //console.log(data);
            if (data.Status !== "Success") {
                return;
            }

            const heartIcons = document.querySelectorAll(
                `.card--favourite.product-${id} i`
            );
            heartIcons.forEach((heart) => {
                heart.className =
                    data.Response.type === "like"
                        ? "icon-heart-filled"
                        : "icon-heart";
            });
        });
};
